import Input from "components/ReduxForm/Input";
import React from "react";
import { Button, ButtonToolbar, Col, Container, Row } from "reactstrap";
import { Field, reduxForm } from "redux-form";

const AgileEditForm = ({ handleSubmit, isInvalid, reset, onCancel }) => {
	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={12} md={4} lg={4} className="form__form-group">
						<span>ID do Cliente Agile:</span>
						<div className="form__form-group-field">
							<Field name="agileCustomerId" component={Input} type="text" />
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={4} className="form__form-group">
						<span>ID da Assinatura Agile:</span>
						<div className="form__form-group-field">
							<Field
								name="agileSubscriptionPlanId"
								component={Input}
								type="text"
							/>
						</div>
					</Col>
				</Row>

				<ButtonToolbar className="form__button-toolbar">
					<Button
						type="button"
						onClick={() => {
							onCancel();
							reset();
						}}
					>
						Cancelar
					</Button>
					<Button color="primary" type="submit" disabled={isInvalid}>
						Salvar
					</Button>
				</ButtonToolbar>
			</Container>
		</form>
	);
};

export default reduxForm({
	form: "agile_edit_form",
	validate
})(AgileEditForm);

const validate = (values = {}) => {
	const errors = {};

	// if (!values.mei) errors.mei = "Informe o contratante";

	return errors;
};
