import Input from "components/ReduxForm/Input";
import validate from "./validate";
import React, { useEffect, useState } from "react";
import {
	Button,
	ButtonToolbar,
	Col,
	Container,
	Row,
	Spinner,
	Tooltip
} from "reactstrap";
import { Field, reduxForm } from "redux-form";
import renderSelectField from "shared/components/form/Select";
import renderDatePickerField from "shared/components/form/DatePicker";
import renderCheckBoxField from "shared/components/form/CheckBox";

const CompanyEdit = ({
	handleSubmit,
	reset,
	loading,
	errors,
	submitting,
	change,
	businessLicenseStatusData,
	nfeBusinessStatusData,
	businessTypeData,
	initialValues,
	companySituationData,
	ditData,
	ditStatusData,
	lifeInsuranceStatusData
}) => {
	const [tooltipUseOwnContractsOpen, setTooltipUseOwnContractsOpen] = useState(
		false
	);

	const [showPurchasedProviders, setShowPurchasedProviders] = useState(
		initialValues.isHired
	);

	const togglePurchasedProviders = () => {
		setShowPurchasedProviders(prevState => {
			return !prevState;
		});
	};

	const toggleUseOwnContracts = () =>
		setTooltipUseOwnContractsOpen(!tooltipUseOwnContractsOpen);

	useEffect(() => {
		setShowPurchasedProviders(initialValues.isHired);
	}, []);

	return (
		<form className="form" onSubmit={handleSubmit}>
			<Container>
				<Row>
					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Tipo de Empresa</span>
						<div className="form__form-group-field">
							<Field
								name="businessType"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={businessTypeData}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Situação da Empresa</span>
						<div className="form__form-group-field">
							<Field
								name="situation"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={companySituationData}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Inscrição municipal</span>
						<div className="form__form-group-field">
							<Field
								name="countyRegistration"
								component={Input}
								type="text"
								placeholder="Ex.:123"
								props={{ errors: errors && errors.countyRegistration }}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Inscrição estadual</span>
						<div className="form__form-group-field">
							<Field
								name="stateRegistration"
								component={Input}
								type="text"
								placeholder="Ex.: 123"
								props={{ errors: errors && errors.stateRegistration }}
							/>
						</div>
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">
							Data de emissão do alvará
						</span>
						<div className="form__form-group-field">
							<Field
								name="businessLicenseEmission"
								component={renderDatePickerField}
								type="text"
								placeholder="Ex.: 10/08/2020"
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Status do alvará</span>
						<div className="form__form-group-field">
							<Field
								name="businessLicenseStatus"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={businessLicenseStatusData}
							/>
						</div>
					</Col>

					<Col xs={12} sm={6} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">
							Data de vencimento do alvará
						</span>
						<div className="form__form-group-field">
							<Field
								name="businessLicenseDue"
								component={renderDatePickerField}
								type="text"
								placeholder="Ex.: 10/08/2020"
							/>
						</div>
					</Col>

					<Col xs={12} md={4} lg={3}>
						<h6 className="bold-text">Arquivo PDF do alvará</h6>
						<Input
							accept="application/pdf"
							type="file"
							name="businessLicenseFile"
							onChange={f => change("businessLicenseFile", f.target.files[0])}
						/>
					</Col>
				</Row>
				<Row>
					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Status Nota Fiscal</span>
						<div className="form__form-group-field">
							<Field
								name="nfeStatus"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={nfeBusinessStatusData}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Empresa Por Medição?</span>
						<div className="form__form-group-field">
							<Field
								name="byMeasurement"
								component={renderCheckBoxField}
								defaultChecked={initialValues.byMeasurement}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span
							className="form__form-group-label"
							id="tooltipUseOwnContracts"
						>
							Usar os contratos próprios da empresa?
						</span>
						<div className="form__form-group-field">
							<Field
								name="useOwnContracts"
								component={renderCheckBoxField}
								defaultChecked={initialValues.useOwnContracts}
							/>
						</div>

						<Tooltip
							placement="top"
							isOpen={tooltipUseOwnContractsOpen}
							target="tooltipUseOwnContracts"
							toggle={toggleUseOwnContracts}
						>
							A empresa utilizará o contrato próprio para seus prestadores e não
							o contrato da SuaMei. Os contratos virão status de Não Optante.
						</Tooltip>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Tem dívida ativa?</span>
						<div className="form__form-group-field">
							<Field
								name="hasActiveDebit"
								component={renderCheckBoxField}
								defaultChecked={initialValues.hasActiveDebit}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">DIT</span>
						<div className="form__form-group-field">
							<Field
								name="dit"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={ditData}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Status DIT</span>
						<div className="form__form-group-field">
							<Field
								name="ditStatus"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={ditStatusData}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">Seguro de Vida</span>
						<div className="form__form-group-field">
							<Field
								name="lifeInsurance"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={ditData}
							/>
						</div>
					</Col>

					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">
							Status Seguro de Vida
						</span>
						<div className="form__form-group-field">
							<Field
								name="lifeInsuranceStatus"
								placeholder="Selecione"
								component={renderSelectField}
								type="text"
								options={lifeInsuranceStatusData}
							/>
						</div>
					</Col>
				</Row>

				<Row>
					<Col xs={12} sm={12} md={4} lg={3} className="form__form-group">
						<span className="form__form-group-label">
							Empresa é contratante?
						</span>
						<div className="form__form-group-field">
							<Field
								name="isHired"
								component={renderCheckBoxField}
								defaultChecked={initialValues.isHired}
								onChange={togglePurchasedProviders}
							/>
						</div>
					</Col>

					{showPurchasedProviders && (
						<Col xs={12} sm={12} md={4} lg={2} className="form__form-group">
							<span className="form__form-group-label">
								Quantidade Assinaturas
							</span>
							<div className="form__form-group-field">
								<Field
									name="purchasedProviders"
									component={Input}
									type="number"
									props={{ errors: errors && errors.purchasedProviders }}
								/>
							</div>
						</Col>
					)}
				</Row>
			</Container>

			<ButtonToolbar className="form__button-toolbar">
				<Button
					color="primary"
					type="submit"
					disabled={submitting || loading.create}
				>
					{loading.create ? (
						<Spinner type="grow" size="sm" color="dark" />
					) : (
						"Salvar"
					)}
				</Button>
				<Button type="button" onClick={reset}>
					Limpar
				</Button>
			</ButtonToolbar>
		</form>
	);
};

export default reduxForm({
	enableReinitialize: true,
	form: "company_edit",
	validate
})(CompanyEdit);
