import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import { toast } from "react-toastify";

import * as api from "services/api";

import PipefyEditFrom from "./pipefyEditForm";

import { getCompanyById } from "../actions";

const PipefyIntegration = () => {
	const dispatch = useDispatch();

	const { item: company } = useSelector(state => state.companyDetail);
	const { token } = useSelector(state => state.login);

	const [edit, setEdit] = useState(false);

	const handleSubmitEdit = async data => {
		const response = await api.sendPatch(
			`/pipefy/update-pipefy-hirer-information?newPipefyId=${data.pipefyId}&companyId=${company._id}`,
			{},
			{
				Authorization: "Bearer " + token
			}
		);

		const result = await response.json();

		if (result.isValid) {
			toast.success("Identificação do Pipefy salva com sucesso!", {
				onClose: () => {
					dispatch(
						getCompanyById(company._id, err => {
							if (err) {
								console.log(err);
							} else {
								setEdit(false);
							}
						})
					);
				}
			});
		} else {
			toast.error("Houve um erro ao salvar dados da empresa!", {
				onClose: () => {
					setEdit(false);
				}
			});
		}
	};

	return (
		<Card>
			<CardBody>
				<Row id="pipefy-integration-title">
					<Col>
						<h4>
							<strong>Integração Pipefy</strong>
						</h4>
					</Col>
				</Row>

				<div className="topbar__menu-divider" />

				{edit ? (
					<PipefyEditFrom
						onSubmit={handleSubmitEdit}
						onCancel={() => setEdit(false)}
						initialValues={{ pipefyId: company?.pipefyId }}
					/>
				) : (
					<>
						<Row className="mt-3">
							<Col>
								<p>
									<strong>ID do Pipefy:</strong>
									<br />
									<span>{company?.pipefyId || ""}</span>
								</p>
							</Col>

							<Col className="text-right">
								<Button color="primary" onClick={() => setEdit(true)}>
									Editar
								</Button>
							</Col>
						</Row>
					</>
				)}
			</CardBody>
		</Card>
	);
};

export default PipefyIntegration;
