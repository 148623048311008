import LoadingComponent from "components/LoadingComponent";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Button, Card, CardBody, Col, Row } from "reactstrap";
import * as api from "services/api";
import AgileEditForm from "./agileEditForm";

const AgileIntegration = () => {
	const { item: company } = useSelector(s => s.companyDetail);
	const { token } = useSelector(state => state.login);

	const [onboardData, setOnboardData] = useState({});
	const [isLoadingFinancialData, setIsLoadingFinancialData] = useState(false);

	const agile_edit_form = useSelector(state => state.agile_edit_form);

	const [edit, setEdit] = useState(false);

	const handleSubmitEdit = async data => {
		const toUpdate = {
			agileCustomerId: data.agileCustomerId,
			agileSubscriptionPlanId: data.agileSubscriptionPlanId
		};

		const response = await api.sendPut(
			`/v2/onboard/update-agile-data/${onboardData._id}`,
			toUpdate,
			{
				Authorization: "Bearer " + token
			}
		);

		if (response.status === 200 || response.status === 201) {
			getOnboardData();

			toast.success("Dados da empresa salvos com sucesso!", {
				onClose: () => {
					setEdit(false);
				}
			});
		} else {
			toast.error("Houve um erro ao salvar dados da empresa!", {
				onClose: () => {
					setEdit(false);
				}
			});
		}
	};

	const getOnboardData = async () => {
		setIsLoadingFinancialData(true);

		const response = await api.sendGet(
			`/v2/onboard/onboard-data/${company._id}`,
			{
				Authorization: "Bearer " + token
			}
		);

		if (!response) {
			console.log("Erro ao buscar dados financeiros");
			return;
		}

		const result = await response.json();

		if (result.isValid) {
			setOnboardData(result.model);
		} else {
			console.log("Erro ao buscar dados financeiros");
		}

		setIsLoadingFinancialData(false);
	};

	const initialValues = {
		agileCustomerId: onboardData?.agileCustomerId,
		agileSubscriptionPlanId: onboardData?.agileSubscriptionPlanId
	};

	useEffect(() => {
		getOnboardData();
	}, []);

	return (
		<Card>
			<CardBody>
				<Row>
					<Col xs={12}>
						<Row id="digisac-integration-title">
							<Col>
								<h4>
									<strong>Integração Agile</strong>
								</h4>
							</Col>
						</Row>

						{isLoadingFinancialData ? (
							<LoadingComponent />
						) : (
							<>
								<div className="topbar__menu-divider" />

								{edit ? (
									<AgileEditForm
										onSubmit={handleSubmitEdit}
										initialValues={initialValues}
										isInvalid={agile_edit_form && !!agile_edit_form.syncErrors}
										onCancel={() => setEdit(false)}
									/>
								) : (
									<>
										<Row className="mt-3">
											<Col>
												<p>
													<strong>ID do Cliente Agile:</strong>
													<br />
													<span>{onboardData.agileCustomerId || ""}</span>
												</p>
											</Col>
											<Col>
												<p>
													<strong>ID da Assinatura Agile:</strong>
													<br />
													<span>
														{onboardData.agileSubscriptionPlanId || ""}
													</span>
												</p>
											</Col>

											<Col className="text-right">
												<Button color="primary" onClick={() => setEdit(true)}>
													Editar
												</Button>
											</Col>
										</Row>
									</>
								)}
							</>
						)}
					</Col>
				</Row>
			</CardBody>
		</Card>
	);
};

export default AgileIntegration;
