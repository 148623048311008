import React from "react";
import { Badge } from "reactstrap";

export function StatusBadge({ status }) {
	switch (status) {
		case 1:
			return (
				<Badge className="w-50" color="info">
					HABILITANDO
				</Badge>
			);
		case 2:
			return (
				<Badge className="w-50" color="success">
					ATIVO
				</Badge>
			);
		case 3:
			return (
				<Badge className="w-50" color="danger">
					INATIVO
				</Badge>
			);
		case 4:
			return (
				<Badge className="w-50" color="secondary">
					NÃO OPTANTE
				</Badge>
			);
		default:
			return (
				<Badge className="w-50" color="secondary">
					PENDENTE
				</Badge>
			);
	}
}
