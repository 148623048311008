import React from "react";

import DigisacIntegration from "./digisac";
import PipefyIntegration from "./pipefy";
import AgileIntegration from "./agile";

const Integrations = () => {
	return (
		<>
			<DigisacIntegration />
			{/* <AgileIntegration /> */}
			<PipefyIntegration />
		</>
	);
};
export default Integrations;
