import ls from "Localization";
import Report from "components/Report";
import ExportExcelForm from "containers/Company/components/ExportExcelForm";
import dayjs from "dayjs";
import ExportExcel from "helpers/exportExcel";
import { toStringCurrency } from "helpers/string";
import React, { createRef, useState } from "react";
import { FaRegFileExcel } from "react-icons/fa";
import { useSelector } from "react-redux";
import {
	Button,
	Container,
	Modal,
	ModalBody,
	ModalHeader,
	UncontrolledTooltip
} from "reactstrap";
import * as api from "services/api";
import { FinancialStatusBadge } from "./financialStatusBadge";

const OnboardHistoryTable = () => {
	const { token } = useSelector(s => s.login);
	const [tableRef] = useState();

	const { item: company } = useSelector(state => state.companyDetail);

	const [isLoading, setIsLoading] = useState(true);
	const [tableData, setTableData] = useState([]);
	const [count, setCount] = useState(0);
	const [pages, setPages] = useState(0);
	const [take, setTake] = useState(5);

	const [modalExportExcel, setModalExportExcel] = useState(false);
	const toggleModalExportExcel = () => setModalExportExcel(!modalExportExcel);

	const [progress, setProgress] = useState(0);

	async function getOnboardHistory(tableState) {
		try {
			if (!tableState) return;

			createRef(tableState);

			let { page, pageSize } = tableState;

			setIsLoading(true);
			setCount(0);

			const response = await api.sendGet(
				`/onboardHistory?filters[companyId]=${
					company._id
				}&take=${pageSize}&skip=${pageSize * page}`,
				{
					Authorization: "Bearer " + token
				}
			);

			const result = await response.json();

			if (result.isValid) {
				setTableData(result.model);
				setCount(result.params.count);
				setPages(Math.ceil(result.params.count / take));
				setTake(pageSize);
			} else {
				setTableData([]);
			}
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	}

	const customData = data => {
		let rows = [];

		for (let item of data) {
			rows.push({
				"Data de Solicitação": item.createdAt
					? dayjs(item.createdAt).format("YYYY-MM-DD")
					: "",
				"Última Atualização": item.updatedAt
					? dayjs(item.updatedAt).format("YYYY-MM-DD")
					: "",
				Assinatura: item.agileSubscriptionPlan
					? `${item.agileSubscriptionPlan}`
					: "",
				Mrr: item.mrr ? `${toStringCurrency(+item.mrr)}` : ""
			});
		}

		return {
			columns: rows[0] ? Object.keys(rows[0]) : [],
			data: rows
		};
	};

	const handleExport = async type => {
		const dataTmp = [];
		const takeTmp = 500;

		for (let skipTmp = 0; skipTmp < count; skipTmp += takeTmp) {
			var response = await api.sendGet(
				`/onboardHistory?skip=${skipTmp}&take=${takeTmp}&sort=createdAt&desc=true&filters=null&filters[companyId]=${company._id}`,
				{
					Authorization: "Bearer " + token
				}
			);

			let result = await response.json();

			if (result.isValid) {
				dataTmp.push(...result.model);
				setProgress(Math.round((skipTmp / count) * 100));
			} else {
				console.log("Erro ao buscar dados do relatório", skipTmp, takeTmp);
				break;
			}
		}

		ExportExcel(customData(dataTmp), "Historico_assinatura", type);
		toggleModalExportExcel();
	};

	const onSubmitExportExcel = data => {
		if (count > 500) {
			confirm(
				"Por favor, aguarde alguns instantes...\nEssa exportação pode demorar um pouco"
			);
		}

		if (data.exportExcel) {
			handleExport("xlsx");
		}

		if (data.exportCsv) {
			handleExport("csv");
		}
	};

	return (
		<Container>
			<Report
				manual
				tableRef={tableRef}
				title={"Histórico de assinatura"}
				data={tableData}
				count={count}
				pages={pages}
				defaultPageSize={take}
				loading={isLoading}
				filterable={false}
				sortable={false}
				noExport={true}
				onFetchData={getOnboardHistory}
				headerRightComponent={
					<div>
						<Button
							className={"mr-2"}
							outline
							onClick={toggleModalExportExcel}
							id="tooltipExcel"
						>
							<FaRegFileExcel style={{ margin: 0 }} />
						</Button>
						<UncontrolledTooltip target="tooltipExcel">
							Exportar os dados da tabela
						</UncontrolledTooltip>
					</div>
				}
				columns={[
					{
						Header: "Data criação",
						id: "createdAt",
						accessor: c =>
							c.createdAt
								? dayjs(c.createdAt).format(ls.dateFormatShort)
								: "--",
						width: 85
					},
					{
						Header: "Assinatura",
						id: "agileSubscriptionPlan",
						accessor: "agileSubscriptionPlan"
					},
					{
						Header: "Mrr",
						id: "mrr",
						accessor: ({ mrr }) => (mrr ? toStringCurrency(+mrr) : "--")
					},
					{
						Header: "Status Financeiro",
						id: "financialLicenseStatus",
						accessor: ({ financialLicenseStatus }) =>
							financialLicenseStatus || financialLicenseStatus === 0
								? FinancialStatusBadge(financialLicenseStatus)
								: "--"
					},
					{
						Header: "Gerente de Contas",
						id: "description",
						accessor: "description",
						show: false
					},
					{
						Header: "Vendedor",
						id: "description",
						accessor: "description",
						show: false
					},
					{
						Header: "Fase",
						id: "description",
						accessor: "description",
						show: false
					},
					{
						Header: "Etapa",
						id: "description",
						accessor: "description",
						show: false
					},
					{
						Header: "Touch",
						id: "description",
						accessor: "description",
						show: false
					}
				]}
			/>

			<Modal isOpen={modalExportExcel} toggle={toggleModalExportExcel}>
				<ModalHeader
					toggle={toggleModalExportExcel}
					className={"card__title bold-text"}
				>
					Exportar Relatório
				</ModalHeader>

				<ModalBody className="theme-light">
					<ExportExcelForm onSubmit={onSubmitExportExcel} progress={progress} />
				</ModalBody>
			</Modal>
		</Container>
	);
};

export default OnboardHistoryTable;
