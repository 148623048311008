import ObligationListView from "containers/Company/containers/Page/Obligation";
import ContactCompanyDetail from "containers/Company/containers/Page/ContactCompany";
import CompanyProviderListView from "containers/Company/containers/Page/CompanyProvider";
import TaxesListView from "containers/Company/containers/Page/Taxes";
import NFesListView from "containers/Company/containers/Page/NFS-e";
import ContractsListView from "containers/Company/containers/Page/Contract";
import OnboradPageView from "containers/Company/containers/Page/Detail/OnBoard";

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReportTable from "components/Report";
import {
	Badge,
	Card,
	CardBody,
	Col,
	Container,
	Dropdown,
	DropdownItem,
	DropdownMenu,
	DropdownToggle,
	Nav,
	NavItem,
	Row,
	NavLink,
	Modal,
	ModalHeader,
	ModalBody
} from "reactstrap";
import DetailCompany from "../../../components/DetailForm/CompanyDetail";
import {
	getCompanyById,
	updateCompanyByReceita,
	getUsersByCompany
} from "./actions";
import { Link } from "react-router-dom";
import Button from "reactstrap/lib/Button";
import { MdMoreVert, MdOpenInNew } from "react-icons/md";
import classNames from "classnames";
import BenefitListView from "containers/Company/containers/Page/Benefit";
import AdminCompany from "./OnBoard/admin";
import NotesCompany from "./Notes";
import SignatureDetail from "./OnBoard/Signature";
import CompanyProviderStatusListView from "../CompanyProviderList";
import Integrations from "./Integrations";
import HirerDashboard from "./Dashboard";
import PipefyHistoryTable from "./OnBoard/Signature/pipefyHistoryTable";

import { toast } from "react-toastify";

import * as api from "services/api";
import InvoiceTable from "./OnBoard/Signature/invoiceTable";
import OnboardHistoryTable from "./OnBoard/Signature/onboardHistoryTable";
import { getOnboardData } from "./OnBoard/actions";
import CompanyHistoryList from "containers/Company/components/CompanyHistoryList";

function Details({
	match: {
		params: { id }
	},
	history
}) {
	const { item, users, loading } = useSelector(state => state.companyDetail);
	const { dashboard } = useSelector(state => state.onBoardDetail);
	const { token } = useSelector(s => s.login);
	const dispatch = useDispatch();
	const { item: onboard } = useSelector(c => c.onBoardDetail);

	useEffect(() => {
		dispatch(getCompanyById(id));
		dispatch(getUsersByCompany(id));
		dispatch(getOnboardData(id, e => console.log(e)));
	}, [dispatch]);

	const [openDropdown, setOpenDropdown] = useState(false);
	const toggle = () => {
		setOpenDropdown(!openDropdown);
	};

	const [isModalOpen, setIsModalOpen] = useState(false);
	const toggleModal = () => {
		setIsModalOpen(prevState => !prevState);
	};

	const [activeTab, setActiveTab] = useState("OnBoard");
	const toggleTabs = tab => {
		if (activeTab !== tab) {
			setActiveTab(tab);
		}
	};

	const updateCompanybyReceita = () => {
		toggle();
		dispatch(updateCompanyByReceita(item?._id));

		return toast.success("Essa ação pode demorar alguns minutos", {
			onClose: () => window.location.reload()
		});
	};

	if (!item) return null;

	const handleFetchData = tableState => {
		if (!tableState) return;
		let { callback } = tableState;

		dispatch(getUsersByCompany(id, callback));
	};

	function providersCount(providers) {
		let providersSum = 0;

		if (providers) {
			providersSum = providers.enabled + providers.onboard + providers.pending;
		}
		return providersSum;
	}

	function adminUsersCount(adminUsers) {
		let adminUsersSum = 0;

		if (adminUsers) {
			const activeAdminUsers = adminUsers.filter(
				adminUser => adminUser.userStatus === 1
			);
			adminUsersSum = activeAdminUsers.length;
		}
		return adminUsersSum;
	}

	async function disableProviders(businessId) {
		try {
			const response = await api.sendPut(
				`/company/disable-provider-batch/${businessId}`,
				null,
				{
					Authorization: "Bearer " + token
				}
			);

			if (!response.ok) {
				throw new Error(`Erro HTTP! Status: ${response.status}`);
			}

			await response.json();

			return toast.success(
				"Inativação Agendada! Isso pode demorar alguns minutos.",
				{
					onClose: () => window.location.reload()
				}
			);
		} catch (error) {
			console.log(error.message);
		} finally {
			setIsModalOpen(false);
		}
	}

	const userBadge = id => {
		return (
			<Badge
				style={{
					cursor: "pointer"
				}}
				color={"primary"}
				target={"_blank"}
				onClick={() => {
					let win = window.open(`/client/${id}`, "_blank");
					win.focus();
				}}
				className={"w-100"}
			>
				Usuário <MdOpenInNew size={16} />
			</Badge>
		);
	};

	const navTabsMenu = (title, show, index) => {
		return show ? (
			<NavItem key={index}>
				<NavLink
					className={classNames({ active: activeTab === title })}
					onClick={() => {
						toggleTabs(title);
					}}
				>
					{title}
				</NavLink>
			</NavItem>
		) : null;
	};

	return (
		<Container className="dashboard">
			<Row>
				<Col xs={12}>
					<Card>
						<CardBody>
							<Row className="align-items-center justify-content-between">
								<div className="card__title">
									<h5 className="bold-text">Detalhes da Empresa</h5>
								</div>
								<div
									style={{
										display: "flex",
										marginLeft: "10px",
										cursor: "pointer",
										gap: "16px"
									}}
								>
									{item.isActive && (
										<Button color="danger" onClick={() => toggleModal()}>
											Inativar
										</Button>
									)}

									<Link to={`/companies/${id}/edit`}>
										<Button color="primary">Editar</Button>
									</Link>
									<Dropdown
										isOpen={openDropdown}
										toggle={() => toggle()}
										direction="right"
									>
										<DropdownToggle tag="div" data-toggle="dropdown">
											<MdMoreVert size={35} color="#646777" />
										</DropdownToggle>
										<DropdownMenu>
											<div id="queryButton">
												<DropdownItem
													onClick={updateCompanybyReceita}
													id="queryButton"
												>
													Atualizar empresa conforme Receita Federal
												</DropdownItem>
											</div>
										</DropdownMenu>
									</Dropdown>
								</div>
							</Row>
							<DetailCompany
								company={{
									...item
								}}
								onboard={onboard}
							/>
						</CardBody>
					</Card>
				</Col>
			</Row>

			<Row>
				<Nav tabs className="ml-1" style={{ cursor: "pointer" }}>
					{[
						{ title: "OnBoard", show: item?.isHired },
						{ title: "Dashboard", show: item?.isHired },
						{ title: "Status Prestadores", show: item?.isHired },
						{ title: "Assinatura", show: item?.isHired },
						{ title: "Contatos", show: item?.isHired },
						{ title: "Administradores", show: item?.isHired },
						{ title: "Detalhes", show: true },
						{ title: "Nota Fiscal", show: true },
						{ title: "Comentários", show: true },
						{ title: "Integrações", show: item?.isHired },
						{ title: "Histórico", show: item?.isHired }
					].map((item, index) => navTabsMenu(item.title, item.show, index))}
				</Nav>
			</Row>

			{item?.isHired && activeTab == "OnBoard" ? (
				<Row>
					<Col xs={12}>
						<Card>
							<CardBody>
								<OnboradPageView
									companyId={id}
									companyCnpj={item.cnpj}
									history={history}
								/>
							</CardBody>
						</Card>
					</Col>
				</Row>
			) : (
				""
			)}

			{activeTab == "Dashboard" && (
				<Row>
					<Col xs={12}>
						<Card>
							<CardBody>
								<HirerDashboard company={item} companyId={id} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			)}

			{item?.isHired && activeTab == "Status Prestadores" ? (
				<Row>
					<Col xs={12}>
						<Card>
							<CardBody>
								<CompanyProviderStatusListView businessId={id} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			) : (
				""
			)}

			{item?.isHired && activeTab == "Contatos" ? (
				<Row>
					<Col xs={12}>
						<Card>
							<CardBody>
								<ContactCompanyDetail companyId={id} />
							</CardBody>
						</Card>
					</Col>
				</Row>
			) : (
				""
			)}

			{activeTab == "Detalhes" ? (
				<div>
					{!item?.isHired && (
						<Row>
							<Col xs={12}>
								<Card>
									<CardBody>
										<ReportTable
											manual
											noExport={true}
											title={"Usuários"}
											data={users}
											loading={loading.get}
											pageSize={users?.length}
											handleFetchData={handleFetchData}
											showPagination={false}
											columns={[
												{
													Header: "Nome",
													filterable: false,
													sortable: false,
													id: "name",
													accessor: c => (c?.name ? c?.name : "--")
												},
												{
													Header: "Email",
													filterable: false,
													sortable: false,
													id: "email",
													accessor: c => (c?.email ? c?.email : "--")
												},
												{
													Header: "Ação",
													filterable: false,
													sortable: false,
													id: "_id",
													accessor: c => (c?._id ? userBadge(c?._id) : "--"),
													width: 80
												}
											]}
										/>
									</CardBody>
								</Card>
							</Col>
						</Row>
					)}

					<Row>
						<Col xs={12}>
							<Card>
								<CardBody>
									<ObligationListView
										companyId={id}
										history={history}
										cnpj={item?.cnpj}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>

					{!item?.isHired && (
						<Row>
							<Col xs={12}>
								<Card>
									<CardBody>
										<CompanyProviderListView companyId={id} history={history} />
									</CardBody>
								</Card>
							</Col>
						</Row>
					)}

					{item?.isHired && (
						<Row>
							<Col xs={12}>
								<Card>
									<CardBody>
										<BenefitListView companyId={id} history={history} />
									</CardBody>
								</Card>
							</Col>
						</Row>
					)}

					<Row>
						<Col xs={12}>
							<Card>
								<CardBody>
									<TaxesListView
										companyId={id}
										history={history}
										cnpj={item?.cnpj}
									/>
								</CardBody>
							</Card>
						</Col>
					</Row>

					<Row>
						<Col xs={12}>
							<Card>
								<CardBody>
									<ContractsListView companyId={id} history={history} />
								</CardBody>
							</Card>
						</Col>
					</Row>
				</div>
			) : (
				""
			)}

			{activeTab == "Nota Fiscal" ? (
				<div>
					<Row>
						<Col xs={12}>
							<NFesListView companyId={id} history={history} />
						</Col>
					</Row>
				</div>
			) : (
				""
			)}
			{activeTab == "Administradores" ? (
				<div>
					<Card>
						<CardBody>
							<Row>
								<Col xs={12}>
									<AdminCompany
										companyCnpj={item.cnpj}
										history={history}
										companyId={item._id}
									/>
								</Col>
							</Row>
						</CardBody>
					</Card>
				</div>
			) : (
				""
			)}

			{activeTab == "Comentários" ? (
				<div>
					<Card>
						<CardBody>
							<Row>
								<Col xs={12}>
									<NotesCompany companyId={id} />
								</Col>
							</Row>
						</CardBody>
					</Card>
				</div>
			) : (
				""
			)}
			{activeTab == "Assinatura" ? (
				<div>
					<Card>
						<CardBody>
							<Row>
								<Col xs={12}>
									<SignatureDetail companyId={id} />
								</Col>
							</Row>
						</CardBody>
					</Card>

					{/* <Card>
						<CardBody>
							<Row>
								<Col xs={12}>
									<InvoiceTable company={item} />
								</Col>
							</Row>
						</CardBody>
					</Card>

					<Card>
						<CardBody>
							<Row>
								<Col xs={12}>
									<OnboardHistoryTable company={item} />
								</Col>
							</Row>
						</CardBody>
					</Card> */}

					<Card>
						<CardBody>
							<Row>
								<Col xs={12}>
									<PipefyHistoryTable />
								</Col>
							</Row>
						</CardBody>
					</Card>
				</div>
			) : (
				""
			)}

			{activeTab == "Integrações" ? <Integrations /> : ""}

			{activeTab == "Histórico" && (
				<Row>
					<Col>
						<CompanyHistoryList companyId={item._id} />
					</Col>
				</Row>
			)}
			<Modal isOpen={isModalOpen} toggle={toggleModal}>
				<ModalHeader
					className={"card__title bold-text"}
					toggle={toggleModal}
					style={{ marginBottom: 0 }}
				>
					Você deseja inativar este contratante?
				</ModalHeader>

				<ModalBody className="theme-light pl-3">
					<p>
						<b>ATENÇÃO, ESSA AÇÃO É IRREVERSÍVEL!</b>
						<br />
						Se inativar essa empresa contratante, todos os prestadores e
						usuários administrativos serão inativados!
					</p>
					<p>
						<b>Serão inativados:</b> {providersCount(dashboard)} Prestadores
					</p>
					<p>
						<b>Serão inativados:</b> {adminUsersCount(users)} Usuários
						Administrativos
					</p>
					<Row className="form__button-toolbar pl-3">
						<Button color="primary" onClick={() => disableProviders(id)}>
							Confirmar
						</Button>
					</Row>
				</ModalBody>
			</Modal>
		</Container>
	);
}

export default Details;
